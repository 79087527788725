<template>
  <div
    id="contactPage"
    class="page-container-table"
  >
    <div style="padding: 15px;">
      <!--    Tên đơn vị-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            1. Tên đơn vị <span class="nRequired">(*)</span>
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_tieude"
            v-model="queryBody.name"
            :placeholder="'Nhập tên đơn vị ... '"
            autocomplete="off"
            autofocus
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.name.required"
            class="text-danger"
          >
            Không được bỏ trống</small>
          <small
            v-if="!$v.queryBody.name.maxLength"
            class="text-danger"
          >
            Tên bài viết có độ dài tối đa
            {{ $v.queryBody.name.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--    Điện thoại-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            2. Điện thoại
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_dienthoai"
            v-model="queryBody.phone"
            :placeholder="'Nhập số điện thoại ... '"
            autocomplete="off"
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.phone.maxLength"
            class="text-danger"
          >
            Số điện thoại có độ dài tối đa
            {{ $v.queryBody.phone.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--    Email-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            3. Email
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_email"
            v-model="queryBody.email"
            :placeholder="'Nhập email ... '"
            autocomplete="off"
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.email.maxLength"
            class="text-danger"
          >
            Số điện thoại có độ dài tối đa
            {{ $v.queryBody.email.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--    Facebook-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            4. Facebook
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_facebook"
            v-model="queryBody.facebook"
            :placeholder="'Nhập facebook ... '"
            autocomplete="off"
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.facebook.maxLength"
            class="text-danger"
          >
            Facebook có độ dài tối đa
            {{ $v.queryBody.facebook.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--    Zalo-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            5. Zalo
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_zalo"
            v-model="queryBody.zalo"
            :placeholder="'Nhập zalo ... '"
            autocomplete="off"
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.zalo.maxLength"
            class="text-danger"
          >
            Zalo có độ dài tối đa
            {{ $v.queryBody.zalo.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--    Youtube-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            6. Youtube
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_youtube"
            v-model="queryBody.youtube"
            :placeholder="'Nhập youtube ... '"
            autocomplete="off"
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.youtube.maxLength"
            class="text-danger"
          >
            Youtube có độ dài tối đa
            {{ $v.queryBody.youtube.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--    Google map-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            7. Google map
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_googleMap"
            v-model="queryBody.googleMap"
            :placeholder="'Nhập google map ... '"
            autocomplete="off"
            class="form-control"
          />
        </b-col>
      </b-row>
      <!--    Đại diện bán hàng-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px"
        >
          <label class="font-weight-600">
            8. Đại diện bán hàng
          </label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-5px"
        >
          <input
            id="nc_id_saleName"
            v-model="queryBody.saleName"
            :placeholder="'Nhập đại diện bán hàng ... '"
            autocomplete="off"
            class="form-control"
          />
          <small
            v-if="!$v.queryBody.saleName.required"
            class="text-danger"
          >
            Không được bỏ trống</small>
          <small
            v-if="!$v.queryBody.saleName.maxLength"
            class="text-danger"
          >
            Đại diện bán hàng có độ dài tối đa
            {{ $v.queryBody.saleName.$params.maxLength.min }} ký tự.</small>
        </b-col>
      </b-row>
      <!--        Button-->
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            class="ml-1"
            :disabled="loading"
            @click="handler_func_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></div>
            <span v-if="loading"> Đang xử lý dữ liệu ...</span>
            <span v-else>Lưu dữ liệu</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { maxLength, required } from 'vuelidate/lib/validators'
import { axiosApiInstance } from '@core/auth/jwt/axiosApiInstance'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import ContactApi from '@/views/contact-manager/constants/ContactApi'

export default {
  components: {
    BButton,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      queryBody: {
        name: '',
        phone: '',
        email: '',
        facebook: '',
        zalo: '',
        youtube: '',
        googleMap: '',
        saleName: '',
      },
      loading: false,
    }
  },
  computed: {},
  mounted() {
    this.callApi()
  },
  validations: {
    queryBody: {
      name: {
        required,
        maxLength: maxLength(500),
      },
      phone: {
        maxLength: maxLength(30),
      },
      email: {
        maxLength: maxLength(250),
      },
      facebook: {
        maxLength: maxLength(500),
      },
      zalo: {
        maxLength: maxLength(500),
      },
      youtube: {
        maxLength: maxLength(500),
      },
      googleMap: '',
      saleName: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    async callApi() {
      const { data } = await axiosApiInstance.get(ContactApi.CONTACT_GET_DETAIL)
      if (data === null) {
        this.queryBody.name = ''
        this.queryBody.phone = ''
        this.queryBody.email = ''
        this.queryBody.facebook = ''
        this.queryBody.zalo = ''
        this.queryBody.youtube = ''
        this.queryBody.googleMap = ''
        this.queryBody.saleName = ''
        this.queryBody.id = ''
      } else {
        this.queryBody.name = data.name
        this.queryBody.phone = data.phone
        this.queryBody.email = data.email
        this.queryBody.facebook = data.facebook
        this.queryBody.zalo = data.zalo
        this.queryBody.youtube = data.youtube
        this.queryBody.googleMap = data.googleMap
        this.queryBody.saleName = data.saleName
        this.queryBody.id = data.id
      }
    },
    async handler_func_button() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NCoreHelper.showMessageError(this, 'Dữ liệu nhập chưa đầy đủ')
      } else {
        this.loading = true
        const iResult = await NCoreHelper.excutePUT(this, ContactApi.CONTACT_UPDATE, this.queryBody, 'Cập nhật liên hệ thành công')
        this.loading = false
      }
    },
  },
}
</script>
